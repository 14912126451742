import React,{ useState, useEffect } from 'react'
import Header from '../Header/Main'
import Footer from '../Footer/Main'
import MenuList3Bred from '../BredCrumb/MenuList3Bred'
import MenuSection3 from '../MenMenuList/MenuSection3'
import Reservation from '../MenMenuList/Reservation'
import Aos from 'aos'
import menuv2 from '../../assets/images/Crunch It Menu v2.jpg'
import menu1v3 from '../../assets/images/menu1.jpg'
import menu2v3 from '../../assets/images/menu2.jpg'
import { Link } from 'react-router-dom'
import MenuBanner from './MenuBanner'



function Main() {
  const dataBaseURL = "https://admin.crunchitgrill.com";
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${dataBaseURL}/items/menu?fields=menuImage.*`);
        const jsonData = await response.json();
        console.log(`jsonData ${JSON.stringify(jsonData)}`);
        setData(jsonData.data);

        Aos.init();
        Aos.refresh();
      } catch (error) {
        // setError(error);
      }
    };
    
    fetchData();
  }, []);

  return (

    <div className='page_wrapper'>
      <Header />
      <MenuList3Bred />
      {/* <MenuBanner /> */}
      {/* <MenuSection3/> */}
      {/* <Reservation/> */}
      {/* <section className="our_menu_section row_inner_am light_texchur"> */}
      <section className="lead" data-aos="fade-up" data-aos-duration={1500} style={{ marginTop: 10}}>
        {/* <div className="container"> */}
          {/* <img src={menu1v3} width={'100%'}/>
          <img src={menu2v3} width={'100%'}/> */}
          {
            data.map((item) => (
              <img src={`${dataBaseURL}/assets/${item.menuImage.id}`} width={'100%'}/>
            ))
          }
        {/* </div> */}

        {/* <div className="text-center" style={{paddingTop: 30}}>
          <Link to="/assets/files/A3 MENU.pdf" className="btn btn_primary">
            download pdf menu
          </Link>
        </div> */}
      </section>
      <Footer />
    </div>
  )
}

export default Main