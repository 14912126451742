import React from 'react'
import { Link } from 'react-router-dom'
import Ctn01 from '../../assets/images/abtbanshape.png'
import Ctn02 from '../../assets/images/contactShape.png'
import Ctn03 from '../../assets/images/contact_icon.png'
// import Ctn04 from '../../assets/images/contact.png'
import Ctn04 from '../../assets/images/Picture 1.jpg'

function ContactUS() {
  return (
    <>
      <section className="contact_us_section">
        <div className="right_shape position-absolute">
          <img src={Ctn01} alt="img" />
        </div>
        <div className="left_shape position-absolute">
          <img src={Ctn02} alt="img" />
        </div>
        <div className="container" style={{ color: 'black' }}>
          <div className="section_title text-center">
            <span className="icon" data-aos="fade-down" data-aos-duration={1500}>
              <img src={Ctn03} alt="img" />
            </span>
            <span className="sub_text mb-1" data-aos="fade-up" data-aos-duration={1500} >
              Contact us
            </span>
            <h2 data-aos="fade-up" data-aos-duration={1500}>
              Get in touch with us
            </h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="radius_img" src={Ctn04} alt="img" style={{objectFit: 'fill'}}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact_box">
                <ul className="contact_listing" data-aos="fade-up" data-aos-duration={1500} >
                  <li>
                    <p className="sub_title">Call us</p>
                    <div className="cnt_block">
                      <Link to="tel:+17088804107">Phone: (708) 880-4107</Link>
                      <span>
                        <i className="icofont-ui-call" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <Link to="tel:+17088801400">Phone: (708) 880-1400</Link>
                      <span>
                        {/* <i className="icofont-whatsapp" /> */}
                        <i className="icofont-ui-call" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <Link to="tel:+17084555088">Fax: (708) 455-5088</Link>
                      <span>
                        <i className="icofont-fax" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <Link to="tel:+18889282838">Toll Free: +1 888 928-2838</Link>
                      <span>
                        <i className="icofont-phone" />
                      </span>
                    </div>
                  </li>
                  {/* <li>
                    <p className="sub_title">Email us</p>
                    <div className="cnt_block">
                      <Link to="mailto:">crunchitgrill@gmail.com</Link>
                      <span>
                        <i className="icofont-envelope-open" />
                      </span>
                    </div>
                  </li> */}
                  <li>
                    <p className="sub_title">Find us</p>
                    <div className="cnt_block">
                      <p>7725 W 159th St, Tinley Park, IL 60477</p>
                      <span>
                        <i className="icofont-location-pin" />
                      </span>
                    </div>
                  </li>
                </ul>
                <form data-aos="fade-up" data-aos-duration={1500} action="https://formspree.io/f/mleqbavd" method='POST'>
                  <h3>Say hi, Don’t be shy!</h3>
                  <div className="form-group">
                    <input name="Name" type="text" className="form-control" placeholder="Your Name" required />
                  </div>
                  <div className="form-group">
                    <input name="Email" type="email" className="form-control" placeholder="Email" required />
                  </div>
                  <div className="form-group">
                    <input name="Phone" type="text" className="form-control" placeholder="Phone Number" required />
                  </div>
                  <div className="form-group">
                    <textarea name="Message" className="form-control" placeholder="Message" defaultValue={""} required />
                  </div>
                  <div className="form-group text-right">
                    <button type="submit" className="btn btn_primary">
                      SUBMIT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="map" data-aos="fade-up" data-aos-duration={1500}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2983.5010814828374!2d-87.80986482321805!3d41.601674971273454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e3e484036002b%3A0x492277f4e0dbbeb6!2s7725%20W%20159th%20St%2C%20Tinley%20Park%2C%20IL%2060477%2C%20USA!5e0!3m2!1sen!2sin!4v1699289345445!5m2!1sen!2sin"
              height={450} style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUS