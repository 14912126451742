import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import FsLightbox from 'fslightbox-react';
// import { createDirectus, rest, readItems} from '@directus/sdk';

import a from '../../assets/images/food/new/img1.jpeg'
import b from '../../assets/images/market/new/img2.jpeg'
import c from '../../assets/images/food/new/img2.jpeg'
import d from '../../assets/images/market/new/img3.jpeg'
import e from '../../assets/images/food/new/img3.jpeg'
import f from '../../assets/images/market/new/img4.jpeg'
import g from '../../assets/images/food/new/img4.jpeg'
import h from '../../assets/images/market/new/img5.jpeg'
import i from '../../assets/images/food/new/img5.jpeg'

import galleryone from '../../assets/images/market/img1.jpeg'
import gallerytwo from '../../assets/images/food/img1.jpeg'
import gallerythree from '../../assets/images/market/img2.jpeg'
import galleryfour from '../../assets/images/food/img2.jpeg'
import galleryfive from '../../assets/images/market/img3.jpeg'
import gallerysix from '../../assets/images/food/img3.jpeg'
import galleryseven from '../../assets/images/market/img4.jpeg'
import galleryeight from '../../assets/images/food/img4.jpeg'
import gallerynine from '../../assets/images/market/img5.jpeg'
import galleryten from '../../assets/images/food/img5.jpeg'
import galleryeleven from '../../assets/images/market/img6.jpeg'
import gallerytwelve from '../../assets/images/food/img6.jpeg'

import gallery13 from '../../assets/images/market/img7.jpeg'
import gallery14 from '../../assets/images/food/img7.jpeg'
import gallery15 from '../../assets/images/market/img8.jpeg'
import gallery16 from '../../assets/images/food/img8.jpeg'
import gallery17 from '../../assets/images/market/img9.jpeg'
import gallery18 from '../../assets/images/food/img9.jpeg'


function Gallery() {
  const dataBaseURL = "https://admin.crunchitgrill.com";
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${dataBaseURL}/items/gallery?fields=image.*`);
        const jsonData = await response.json();
        console.log(`jsonData ${JSON.stringify(jsonData)}`);
        setData(jsonData.data);
      } catch (error) {
        // setError(error);
      }
    };
  
    fetchData();
  }, []);

  const [images, setImages] = useState([
    a,b,c,d,e,f,g,h,i,

    galleryone,
    gallerytwo,
    gallerythree,
    galleryfour,
    galleryfive,
    gallerysix,
    galleryseven,
    galleryeight,
    gallerynine,
    galleryten,
    galleryeleven,
    gallerytwelve,

    gallery13,
    gallery14,
    gallery15,
    gallery16,
    gallery17,
    gallery18,
  ])

  const [aa, setaa] = useState()
  const [bb, setbb] = useState()
  const [cc, setcc] = useState()
  const [dd, setdd] = useState()
  const [ee, setee] = useState()
  const [ff, setff] = useState()
  const [gg, setgg] = useState()
  const [hh, sethh] = useState()
  const [ii, setii] = useState()

  const [img, setImg] = useState()
  const [img1, setImg1] = useState()
  const [img2, setImg2] = useState()
  const [img3, setImg3] = useState()
  const [img4, setImg4] = useState()
  const [img5, setImg5] = useState()
  const [img6, setImg6] = useState()
  const [img7, setImg7] = useState()
  const [img8, setImg8] = useState()
  const [img9, setImg9] = useState()
  const [img10, setImg10] = useState()
  const [img11, setImg11] = useState()

  const [img12, setImg12] = useState()
  const [img13, setImg13] = useState()
  const [img14, setImg14] = useState()
  const [img15, setImg15] = useState()
  const [img16, setImg16] = useState()
  const [img17, setImg17] = useState()

  const [toggler, setToggler] = useState(false);

  const [activeImage, setActiveImage] = useState(1)



  return (
    <>
      <section className="row_am gallery_section">
        <div className="container">
          {/* <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={a} alt="img" />
                <Link to="#" onClick={() => setImg(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(1) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={b} alt="img" />
                <Link to="#" onClick={() => setImg(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(2) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={c} alt="img" />
                <Link to="#" onClick={() => setImg(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(3) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={d} alt="img" />
                <Link to="#" onClick={() => setImg(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(4) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={e} alt="img" />
                <Link to="#" onClick={() => setImg(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(5) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={f} alt="img" />
                <Link to="#" onClick={() => setImg(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(6) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={g} alt="img" />
                <Link to="#" onClick={() => setImg(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(7) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={h} alt="img" />
                <Link to="#" onClick={() => setImg(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(8) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={i} alt="img" />
                <Link to="#" onClick={() => setImg(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(9) }}>
                </Link>
              </div>
            </div>



            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryone} alt="img" />
                <Link to="#" onClick={() => setImg(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(10) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={gallerytwo} alt="img" />
                <Link to="#" onClick={() => setImg1(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(11) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={gallerythree} alt="img" />
                <Link to="#" onClick={() => setImg2(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(12) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryfour} alt="img" />
                <Link to="#" onClick={() => setImg3(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(13) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryfive} alt="img" />
                <Link to="#" onClick={() => setImg4(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(14) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={gallerysix} alt="img" />
                <Link to="#" onClick={() => setImg5(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(15) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryseven} alt="img" />
                <Link to="#" onClick={() => setImg6(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(16) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryeight} alt="img" />
                <Link to="#" onClick={() => setImg7(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(17) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={gallerynine} alt="img" />
                <Link to="#" onClick={() => setImg8(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(18) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryten} alt="img" />
                <Link to="#" onClick={() => setImg9(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(19) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={galleryeleven} alt="img" />
                <Link to="#" onClick={() => setImg10(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(20) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={gallerytwelve} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(21) }}>
                </Link>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={gallery13} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(22) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={gallery14} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(23) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={gallery15} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(24) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={gallery16} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(25) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={gallery17} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(26) }}>
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="img-fluid" src={gallery18} alt="img" />
                <Link to="#" onClick={() => setImg11(true)} className="lightbox-image" data-fancybox="gallery">
                  <i className="icofont-ui-zoom-in" />
                </Link>
                <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(27) }}>
                </Link>
              </div>
            </div>
          </div> */}

          <dic className="row">
            {
              data.map((item, i) => (
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                    <img className="img-fluid" src={`${dataBaseURL}/assets/${item.image.id}`} alt="img" />
                    <Link to="#" onClick={() => setImg(true)} className="lightbox-image" data-fancybox="gallery">
                      <i className="icofont-ui-zoom-in" />
                    </Link>
                    <Link to="#" onClick={() => { setToggler(!toggler); setActiveImage(i+1) }}>
                    </Link>
                  </div>
                </div>
              ))
            }
          </dic>
        </div>
      </section>
      {/* <FsLightbox toggler={toggler} sources={images} slide={activeImage} /> */}
      <FsLightbox toggler={toggler} sources={data.map(item => `${dataBaseURL}/assets/${item.image.id}`)} slide={activeImage} />
    </>
  )
}

export default Gallery