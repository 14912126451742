import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Blog1 from '../../assets/images/author1.png'
import Blog2 from '../../assets/images/food/img9.jpeg'

function BlogDetail() {
  const dataBaseURL = "https://admin.crunchitgrill.com";
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${dataBaseURL}/items/about`);
        const jsonData = await response.json();
        console.log(`jsonData ${JSON.stringify(jsonData)}`);
        setData(jsonData.data);
      } catch (error) {
        // setError(error);
      }
    };
  
    fetchData();
  }, []);

  return (
    <>
      <section className="blog_detail_section" data-aos="fade-up" data-aos-duration={2000} data-aos-delay={200} >
        <div className="container">
          <div className="blog_inner_pannel" style={{ backgroundColor: "#fee2b3", color: "black" }}>
            <div className="blog_info" data-aos="fade-up" data-aos-duration={2000} >
              <center>
                <h1 style={{ color: "black" }}>{data.title}</h1>
                <h2>{data.subTitle}</h2>
              </center>
              <div dangerouslySetInnerHTML={{ __html: data.description }} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogDetail;
