import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import DishImg1 from '../../assets/images/chiken_side.png'
import DishImg2 from '../../assets/images/chiken_side2.png'

const Popular = {
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
}

function PopularDish() {
  return (
    <>
      <section className="row_inner_am popular_dish_section">
        <div className="side_shape">
          <img className="right_side" src={DishImg1} alt="img" />
          <img className="left_side" src={DishImg2} alt="img" />
        </div>
        <div className="container">
          <div className="section_title text-center" data-aos="fade-up" data-aos-duration={1500} >
            <span className="sub_text">Welcome to</span>
            <h1>Crunch It</h1>
            {/* <h1>(Coming Soon)</h1> */}
            {/* <p>Explore the Richness of Middle Eastern Cuisine. Savor the exotic flavors and spices of our authentic dishes.</p> */}
          </div>
          <div id="popular_dish" className="dishList_slider" data-aos="fade-in" data-aos-duration={1500} >
            <Slider className="Slider-dishes" {...Popular} slidesToShow={4} arrows={false} margin={15} autoplay={true} autoplaySpeed={1000}>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    {/* <img src="assets/images/food/img1.jpeg" alt="img" /> */}
                    <img src="assets/images/food/new/img7.jpeg" alt="img" />
                  </div>
                  <h3>burger</h3>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    {/* <img src="assets/images/food/img2.jpeg" alt="img" /> */}
                    <img src="assets/images/food/new/img9.jpeg" alt="img" />
                  </div>
                  <h3>Kebabs</h3>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    {/* <img src="assets/images/food/img3.jpeg" alt="img" /> */}
                    <img src="assets/images/food/new/img14.jpeg" alt="img" />
                  </div>
                  <h3>Snacks</h3>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    {/* <img src="assets/images/food/img4.jpeg" alt="img" /> */}
                    <img src="assets/images/food/new/img8.jpeg" alt="img" />
                  </div>
                  <h3>Wraps</h3>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    {/* <img src="assets/images/food/img5.jpeg" alt="img" /> */}
                    <img src="assets/images/food/new/img12.jpeg" alt="img" />
                  </div>
                  {/* <h3>Variety of dips</h3> */}
                  <h3>Salads</h3>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    {/* <img src="assets/images/food/img6.jpeg" alt="img" /> */}
                    <img src="assets/images/food/new/img3.jpeg" alt="img" />
                  </div>
                  {/* <h3>Fries</h3> */}
                  <h3>Desserts</h3>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    {/* <img src="assets/images/food/img7.jpeg" alt="img" /> */}
                    <img src="assets/images/food/new/img5.jpeg" alt="img" />
                  </div>
                  {/* <h3>Wraps</h3> */}
                  <h3>Turkish Coffee</h3>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    {/* <img src="assets/images/food/img8.jpeg" alt="img" /> */}
                    <img src="assets/images/food/new/img2.jpeg" alt="img" />
                  </div>
                  <h3>Fried Chickem</h3>
                </div>
              </div>
            </Slider>
          </div>
          <div className="text-center mb-md-4">
            <Link to="/menu" className="btn btn_primary">
              view our full menu
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default PopularDish