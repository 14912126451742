import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick';
import Icon01 from '../../assets/images/title_icon.png'
import Icon02 from '../../assets/images/who_icon.png'
import Icon03 from '../../assets/images/who_icon2.png'
// import gallery1 from '../../assets/images/food/img7.jpeg'
// import gallery2 from '../../assets/images/market/img1.jpeg'
// import gallery3 from '../../assets/images/food/img19.jpeg'
// import gallery4 from '../../assets/images/market/img2.jpeg'
// import gallery5 from '../../assets/images/food/img25.jpeg'
// import gallery6 from '../../assets/images/market/img3.jpeg'
// import gallery7 from '../../assets/images/food/img18.jpeg'
// import gallery8 from '../../assets/images/market/img4.jpeg'
import gallery1 from '../../assets/images/food/new/img13.jpeg'
import gallery2 from '../../assets/images/market/new/img1.jpeg'
import gallery3 from '../../assets/images/food/new/img1.jpeg'
import gallery4 from '../../assets/images/market/new/img5.jpeg'
import gallery5 from '../../assets/images/food/new/img11.jpeg'
import gallery6 from '../../assets/images/market/new/img4.jpeg'
import gallery7 from '../../assets/images/food/new/img10.jpeg'
import gallery8 from '../../assets/images/market/new/img6.jpeg'

const Weslider = {
    responsive: [
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 4,
            },
        },
    ],
}

function WhoWe() {
    return (
        <>
            <div className="row_inner_am who_we_section who_version_two light_texchur">
                <div className="container">
                    {/* <div className="section_title text-center" data-aos="fade-up" data-aos-duration={1500} > */}
                    <div className="section_title text-center">
                        <div className="icon">
                            <img src={Icon01} alt="img" />
                        </div>
                        <span className="sub_text">Visit Us</span>
                        <h2>
                            Explore the Richness of Middle Eastern Cuisine. <br />{" "}
                            Savor the exotic flavors and spices of our authentic dishes.
                        </h2>
                    </div>
                </div>
                <div className="gallery_slider">
                    {/* <Slider className="Slider-Who" id="gallery_flow_right" data-aos="fade-in" data-aos-duration={1500} {...Weslider} slidesToShow={11.5} arrows={false} autoplay={true} autoplaySpeed={1000}> */}
                    <Slider className="Slider-Who" id="gallery_flow_right" data-aos="fade-in" data-aos-duration={1500} slidesToShow={11.5} arrows={false} autoplay={true} autoplaySpeed={1000}>
                        <div className="item">
                            <div className="img">
                                <img src={gallery1} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery2} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery3} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery4} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery1} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery2} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery3} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery4} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery1} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery2} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery3} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery4} alt="img" />
                            </div>
                        </div>
                    </Slider>
                    {/* <Slider className="Slider-Who" id="gallery_flow_left" data-aos="fade-in" data-aos-duration={1500} {...Weslider} rtl={true} slidesToShow={11.5} arrows={false} autoplay={true} autoplaySpeed={1000} > */}
                    <Slider className="Slider-Who" id="gallery_flow_left" data-aos="fade-in" data-aos-duration={1500} rtl={true} slidesToShow={11.5} arrows={false} autoplay={true} autoplaySpeed={1000} >
                        <div className="item">
                            <div className="img">
                                <img src={gallery5} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery6} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery7} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery8} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery5} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery6} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery7} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery8} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery5} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery6} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery7} alt="img" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={gallery8} alt="img" />
                            </div>
                        </div>
                    </Slider>
                </div>
                {/* <div className="text-center" data-aos="fade-in" data-aos-duration={1500}> */}
                <div className="text-center">
                    <Link to="/About" className="btn btn_primary">
                        read our story
                    </Link>
                </div>
            </div>
        </>
    )
}

export default WhoWe